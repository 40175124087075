import etsyTag from '@/lib/promotional-etsy-tag.jpeg'
import mapLeads from '@/lib/promotional-map-leads.jpeg'
import etsyReviews from '@/lib/promotional-etsy-reviews.png'

const Home = () => {

    return (
        <>
            <div className="flex flex-col pt-28 pb-5 items-center">
                <p className="text-center font-semibold text-5xl ">
                    Extend your browser with an extension booster
                </p>
                <p className="flex flex-col items-center md:flex-row  text-gray-600 font-light pt-7">
                    Our handy <span className="font-bold text-2xl px-1 text-red-600">Browser extension</span>  will help maximize your productivity and efficiency by streamlining your browsing experience.
                </p>
                <div className=" w-full mx-auto mt-32 bg-blue-50">
                    <div className=" flex flex-col break-words w-full mb-1 rounded">
                        <div className="w-full">


                            <section class="text-gray-600 body-font">
                                <div class="container px-5 py-20 mx-auto">
                                    <div className="flex flex-wrap -m-4">

                                        <div className="p-4 md:w-1/3">
                                            <div
                                                className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                                <a target="_blank" rel="noreferrer"
                                                   href="https://chromewebstore.google.com/detail/etsy-tag-scraper/lmnmjhjmidlfconecbodncmemgbgodka?hl=en">
                                                    <img className="lg:h-48 md:h-36 w-full object-cover object-center"
                                                         src={etsyTag} alt="etsyTag"/>
                                                </a>

                                                <div className="p-6">
                                                    <h1 className="title-font text-lg font-semibold text-gray-700 mb-3">Etsy
                                                        Tag Scraper</h1>
                                                    <p className="leading-relaxed mb-3">A smart keyword SEO tool for
                                                        Etsy, find Etsy listing tags, one click to copy all Etsy listing
                                                        tags.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-4 md:w-1/3">
                                            <div
                                                className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                                <a target="_blank" rel="noreferrer"
                                                   href="https://mapleads.z-booster.com/">
                                                    <img className="lg:h-48 md:h-36 w-full object-cover object-center"
                                                         src={mapLeads} alt="mapLeads"/>
                                                </a>
                                                <div className="p-6">
                                                    <h1 className="title-font text-lg font-semibold text-gray-700 mb-3">Map
                                                        Leads Extractor</h1>
                                                    <p className="leading-relaxed mb-3">Automatically extracts key data
                                                        from Google Maps and outputs clean CSV files for seamless lead
                                                        follow-up.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-4 md:w-1/3">
                                            <div
                                                className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                                                <a target="_blank" rel="noreferrer"
                                                   href="https://etsyreviews.z-booster.com/">
                                                    <img className="lg:h-48 md:h-36 w-full object-cover object-center"
                                                         src={etsyReviews} alt="etsyReviews"/>
                                                </a>

                                                <div className="p-6">
                                                    <h1 className="title-font text-lg font-semibold text-gray-700 mb-3">Etsy
                                                        Reviews Extractor</h1>
                                                    <p className="leading-relaxed mb-3">A smart tool for extract Etsy Reviews by one click, find Etsy Reviews tags, one click to download all reviews.</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default Home;